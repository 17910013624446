import React, { useEffect, useRef, useState } from 'react'
import './LocationReviews.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useLocation, useNavigate } from 'react-router-dom';
import Google from '../../Images/Google.png'
import book from '../../Images/book.png'
import telephone from '../../Images/telephone.png'
import Review from '../../Images/Review.png'
import locationpin from '../../Images/locationpin.png'
import LocationReviewsData from './LocationReviewsData'
import LocationData from '../LocationAll/LocationData'
import GoogleMapReact from "google-map-react";

//

export default function LocationReviews() {
    const location = useLocation()
    const mapRef = useRef(null);
    const [LoactionCity, setLoactionCity] = useState(LocationData.find(a => a.City.replace(' ', '').toLocaleLowerCase() == location.pathname.split('/')[2]).City);
    const lenth = LocationReviewsData.filter(a => a.location == LoactionCity).length
    const avg = LocationReviewsData.filter(a => a.location == LoactionCity).reduce((a, b) => a += b.review_rate, 0);
    // console.log(avg / lenth);

    return (
        <div className="LocationReviews">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>{LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Garage Door Reviews | {avg ? (avg / lenth).toFixed(2) : "5" + " Stars Based on " + (lenth ? LocationReviewsData.filter(a => a.location == LoactionCity).length : "1818") + " Reviews"} | Experts Irvine Garage Door</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content={`${LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Garage Door Reviews | ${avg ? (avg / lenth).toFixed(2) : "5" + " Stars Based on " + lenth ? LocationReviewsData.filter(a => a.location == LoactionCity).length : "1818" + " Reviews"} | Experts Irvine Garage Door`} data-react-helmet="true" />
                <meta name="og:title" property="og:title" content={`${LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Garage Door Reviews | ${avg ? (avg / lenth).toFixed(2) : "5" + " Stars Based on " + lenth ? LocationReviewsData.filter(a => a.location == LoactionCity).length : "1818" + " Reviews"} | Experts Irvine Garage Door`} data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content={`https://www.irvinegaragedoor.org/reviews/${location.pathname.split('/')[2]}`} data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content={`Discover Our Reviews At ${LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Garage Door Location | ${avg ? (avg / lenth).toFixed(2) : "5" + " Stars Based on " + lenth ? LocationReviewsData.filter(a => a.location == LoactionCity).length : "1818" + " Reviews"} Garage Door Repair in ${LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)}, CA area and surrounding cities.`} data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content={`Discover Our Reviews At ${LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Garage Door Location | ${avg ? (avg / lenth).toFixed(2) : "5" + " Stars Based on " + lenth ? LocationReviewsData.filter(a => a.location == LoactionCity).length : "1818" + " Reviews"} Garage Door Repair in ${LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)}, CA area and surrounding cities.`} data-react-helmet="true" />
                <meta name="keywords" content="Garage Dooring, Garage Door, Garage Doorinspection, Garage Door repair, Garage Door maintenance, Creosote removal, Garage Doorsafety, Garage Doorflue Repair, Soot removal, Garage Door Maintenance Locations" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content={`Irvine Garage Door`} data-react-helmet="true" />
            </Helmet>
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>See what customers are saying about {LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Garage Door in your area:</h1>
                    <h2>Welcome to {LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)}'s Premier Garage Door Service Provider - Reviews Page</h2>
                    <span>
                        <a href="tel:310-929-6134">CALL 310-929-6134<img src={telephone} alt="" /></a>
                        <a href="/book">BOOK AN APPOINTMENT<img src={book} alt="" /></a>
                    </span>
                    <div className="GoogleButt">
                        <img src={Google} alt={`${LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Garage Door Reviews`} />
                        <h6>{avg ? (avg / lenth).toFixed(2) : "5"} Stars - Based on {lenth ? lenth : "1818"} Reviews For {LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Garage Door Repair</h6>
                        <a href="/locations">Read Other Locations Reviews</a>
                    </div>
                </div>

            </div>

            <div className="LocationReviewsListMap">
                <div className="LocationReviewsLsit">
                    {LocationReviewsData.filter(a => a.location.toLowerCase() == LoactionCity.toLocaleLowerCase()).map((A, index) =>
                        <div key={index} className="LocationReviewsBox">
                            <div className="LocationReviewsBoxHeader">
                                <div className="LocationReviewsBoxHeaderBox">
                                    <h1>{A.service_type}</h1>
                                    <h2>{A.location}, CA</h2>
                                </div>
                                <div className="LocationReviewsBoxHeaderBox">
                                    <h1>⭐⭐⭐⭐⭐</h1>
                                    <h2>{A.date}</h2>
                                </div>
                            </div>

                            <div className="LocationReviewsBoxLeft">
                                <h3>Open Now 24/7 • Free Estimates • Same Day Service</h3>
                                <h2>Review Rated {A.review_rate} By {A.customer_name}</h2>
                                <h4>{A.review}</h4>
                                <div className="LocationReviewsBoxLeftBox">
                                    <div className="LocationReviewsBoxLeftBoxBox">
                                        <h5>Service Provided:</h5>
                                        <h6>{A.service_type}</h6>
                                    </div>
                                    <div className="LocationReviewsBoxLeftBoxBox">
                                        <h5>Technician</h5>
                                        <h6>{A.tech_name}</h6>
                                    </div>
                                    <div className="LocationReviewsBoxLeftBoxBox">
                                        <h5>More Details About This Service/Location</h5>
                                        <h6>Garage Door <a href={`${LocationData.find(a => a.City == A.location).Url}`}>{A.location}, CA</a> Providing  <a href={`${LocationData.find(a => a.City == A.location).Url + `/service/${A.service_type.replaceAll(' ', '-')}`}`}>{A.service_type}</a></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {LocationData.map((A, index) =>
                        <div key={index} className="Header1BoxMenuBox">
                            <div className="LocationAllBox">
                                <div className="LocationAllBoxLeft">
                                    <h2>{A.City}, {A.State}</h2>
                                    <h3>Open Now 24/7 • Free Estimates • Same Day Service</h3>
                                    <h4>Serving Garage Door in {A.City}, CA area and surrounding cities.</h4>
                                    <h5><a href={A.Url} target='_blank'>VISIT THIS LOCATION</a> - <a href={`${A.Url}/services`} target='_blank'>SEE SERVICES</a></h5>
                                    <span>
                                        <a href={`tel:${A.Phone}`}>
                                            <img src={[telephone]} alt={`${A.Phone} - ${A.City} Garage Door`} />{A.Phone}
                                        </a>

                                        <a href={`${A.Url}/book`}>
                                            <img src={[book]} alt={`SCHEDULE ONLINE ${A.City} Garage Door`} />SCHEDULE ONLINE
                                        </a>

                                        <a href={`/reviews/${A.City.replace(' ', '').toLowerCase()}`} >
                                            <img src={[locationpin]} alt={`Read reviews of ${A.City} Garage Door`} />READ REVIEWS
                                        </a>
                                    </span>
                                </div>
                                
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <NavigatorPath />
        </div>
    )
}

